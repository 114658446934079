<template>
  <div class="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
    <div
      class="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-xl shadow-2xl"
    >
      <div class="flex flex-col overflow-y-auto md:flex-row">
        <div class="h-32 md:h-auto md:w-1/2">
          <img
            aria-hidden="true"
            class="object-cover w-full h-full dark:hidden"
            src="@/assets/background.jpg"
            alt="bg"
            lazy="true"
          />
          <img
            aria-hidden="true"
            class="hidden object-cover w-full h-full dark:block"
            src="@/assets/background.jpg"
            alt="bg"
            lazy="true"
          />
        </div>
        <div
          class="flex items-center justify-center p-6 sm:p-12 md:w-1/2 my-24"
        >
          <div class="w-full flex flex-col space-y-2">
            <div>
              <h1 class="mb-4 text-4xl text-center font-semibold text-gray-700">
                Login
              </h1>
            </div>
            <div>
              <label class="block">
                <span class="label">Email</span>
                <input
                  type="email"
                  class="input"
                  placeholder="email@example.com"
                  v-model="$v.user.email.$model"
                />
              </label>
            </div>
            <div>
              <label class="block">
                <span class="label">Password</span>
                <input
                  type="password"
                  class="input"
                  placeholder="Your password"
                  v-model="$v.user.password.$model"
                />
              </label>
            </div>
            <div>
              <button @click="login()" class="btn">
                Log in
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  name: "LoginComponent",
  mixins: [validationMixin],
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
    };
  },
  components: {},
  validations: {
    user: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  mounted() {},
  methods: {
    login() {
      if (!this.$v.$invalid) {
        this.$store.dispatch("auth/attemptLogin", {
          ...this.user,
          redirect: this.$route.query.redirect,
        });
      }
    },
  },
};
</script>

<style></style>
